import React from 'react';
import styled from 'styled-components'

import Timeline, { timelineClasses } from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';

const Document = styled.img`
  display: none;
  height: 70px;
  width: fit-content;
  background-color: #000;
  border-radius: 10px;
  &:hover{
    cursor: pointer;
    opacity: 0.8;
  }
`

const Description = styled.div`
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_primary + 99};
  margin-bottom: 10px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`

const ListSkills = styled.div`
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_primary + 99};
  margin-bottom: 10px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`

const Span = styled.span`
  display: -webkit-box;
  max-width: 100%;

  transition: height 1s ease;
`;

const Card = styled.div`
  width: 650px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  padding: 12px 16px;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 12px;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
    transform: translateY(-5px);
  }
  @media only screen and (max-width: 768px) {
    padding: 10px;
    gap: 8px;
    width: 300px;
  }

  &:hover ${Document} {
    display: flex;
  }
  border: 0.1px solid #306EE8;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 20px;
  
`

const Top = styled.div`
  width: 100%;
  display: flex;
  gap: 12px
`

const Image = styled.img`
  height: 50px;
  background-color: #000;
  border-radius: 10px;
  margin-top: 4px;
  @media only screen and (max-width: 768px) {
    height: 40px;
  }
`

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column; 
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary + 99};
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`

const Company = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.text_secondary + 99};
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`
const Role = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_primary + 80};
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`

const Date = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_secondary + 80};
  @media only screen and (max-width: 768px) {
    font-size: 10px;
  }
`

const Skills = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  margin-top: -10px;
`

const ItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

const Skill = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_primary + 99};
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`

const TimelineSection = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const ExperienceCard = ({ experience }) => {
  return (
    <Card>
      <Top>
        <Image src={experience.img} />
        <Body>
          <Title>{experience.role}</Title>
          <Company>{experience.company}</Company>
        </Body>
      </Top>
      <Description>
        <TimelineSection>
          <Timeline class="test"
            sx={{
              [`&`]: {
                padding: 0,
              },
              [`& .${timelineItemClasses.root}:before`]: {
                padding: 0,
              },
            }}
          >
            {experience?.timeline?.map((line, index) => (
              <>
                <TimelineItem key={index}>
                  <TimelineSeparator>
                    {experience?.timeline.length > 1 && (
                      <>
                        <TimelineDot variant="outlined" color="primary" />
                        {index !== experience?.timeline.length - 1 && <TimelineConnector style={{ background: '#595be7' }} />}
                      </>
                    )}
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: '12px', px: 1 }}>
                    <Date>{line.date}</Date>
                    <Role>{line.role}</Role>
                    <Span>
                      {line.desc}
                    </Span>
                  </TimelineContent>
                </TimelineItem>
              </>
            ))}
          </Timeline>
        </TimelineSection>
      </Description>
      <ListSkills>
        {experience?.skills &&
          <>
            <br />
            <Skills>
              <b>Skills:</b>
              <ItemWrapper>
                {experience?.skills?.map((skill, index) => (
                  <Skill>• {skill}</Skill>
                ))}
              </ItemWrapper>
            </Skills>
          </>
        }
      </ListSkills>
      {experience.doc &&
        <a href={experience.doc} target="new">
          <Document src={experience.doc} />
        </a>
      }
    </Card>
  )
}

export default ExperienceCard