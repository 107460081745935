import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import React from 'react';
import styled from 'styled-components';
import { experiences } from '../../../data/constants';
import ExperienceCard from '../../cards/ExperienceCard';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  padding: 40px 0;

  @media (max-width: 960px) {
    padding: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1350px;
  padding-top: 80px;
  gap: 12px;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-size: 42px;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};

  @media (max-width: 768px) {
    font-size: 32px;
    margin-top: 12px;
  }
`;

const Desc = styled.div`
  font-size: 18px;
  max-width: 600px;
  text-align: center;
  color: ${({ theme }) => theme.text_secondary};

  @media (max-width: 768px) {
    font-size: 16px;
    margin-top: 12px;
  }
`;

const TimelineSection = styled.div`
  width: 100%;
  max-width: 1000px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const ExperienceTimeline = () => {
  return (
    <Container id="experience">
      <Wrapper>
        <Title>Experiência</Title>
        <Desc>
          Minha experiência de trabalho como desenvolvedor e trabalhando em diferentes empresas e projetos.
        </Desc>
        <TimelineSection>
          <Timeline sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                content: 'none', 
                flex: 0,
                padding: 0,
              },
            }}>
            {experiences.map((experience, index) => (
              <TimelineItem key={index}>
                <TimelineContent sx={{ py: '12px', px: 1 }}>
                  <ExperienceCard experience={experience} />
                </TimelineContent>
                <TimelineSeparator>
                  <TimelineDot variant="outlined" color="secondary" />
                  {index !== experiences.length - 1 && <TimelineConnector style={{ background: '#9b27b0' }} />}
                </TimelineSeparator>
              </TimelineItem>
            ))}
          </Timeline>
        </TimelineSection>
      </Wrapper>
    </Container>
  );
};

export default ExperienceTimeline;
