import React from 'react';
import styled from "styled-components";
import Typewriter from 'typewriter-effect';
import { Bio } from '../../../data/constants';
import HeroImg from '../../../images/hero.png';

export const HeroContainer = styled.div`
  background: linear-gradient(calc(188deg), rgb(0 137 255 / 15%) 19.96%, rgba(179, 19, 199, 0.06) 41.27%, rgb(0 0 0 / 75%) 55.83%, rgb(2 2 2 / 65%) 71.75%) rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  position: relative;
  padding: 80px 30px;
  min-height: calc(100vh - 80px);
  @media (max-width: 960px) {
    padding: 66px 16px;
  }
  @media (max-width: 640) {
    padding: 32px 16px;
  }
  z-index: 1;
`;

export const HeroInnerContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1100px;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const HeroRightContainer = styled.div`
  order: 2;
  @media (max-width: 960px) {
    order: 2;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 640px) {
    order: 2;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const HeroLeftContainer = styled.div`
  width: 100%;
  display: flex;
  order: 1;
  justify-content: start;
  gap: 12px;
  
  @media (max-width: 960px) {
    order: 1;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;
  }

  @media (max-width: 640px) {
    margin-bottom: 30px;
  }
`;

export const ImgContainer = styled.div`
  width: auto;
  height: auto;
  border-radius: 50%;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  background: linear-gradient(to right, #12c2e9, #c471ed, #e60278);
  -webkit-background-clip: border;
  -webkit-border-fill-color: transparent;
  border: 4px solid transparent;
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  max-width: 300px;
  max-height: 300px;

  @media (max-width: 768px) {
    max-width: 300px;
    max-height: 300px;
  }

  @media (max-width: 640px) {
    max-width: 280px;
    max-height: 280px;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 50px;
  color: ${({ theme }) => theme.text_primary};
  line-height: 68px;
  @media (max-width: 960px) {
    text-align: center;
  }

  @media (max-width: 640px) {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 8px;
  }
`;

export const TextLoop = styled.div`
  font-weight: 400;
  font-size: 32px;
  display: flex;
  gap: 12px;
  color: ${({ theme }) => theme.text_primary};
  line-height: 68px;
  @media (max-width: 960px) {
    text-align: center;
  }
  @media (max-width: 640px) {
    font-size: 22px;
    line-height: 48px;
    margin-bottom: 16px;
  }
`;

export const Span = styled.span`
  background: linear-gradient(to right, #12c2e9, #c471ed, #e60278);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const SubTitle = styled.div`
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 42px;
  color: ${({ theme }) => theme.text_primary + 95};

  @media (max-width: 960px) {
    text-align: center;
  }

  @media (max-width: 640px) {
    font-size: 16px;
    line-height: 32px;
  }
`;

const HeroSection = () => {
  return (
    <div id="about">
      <HeroContainer>
        <HeroInnerContainer>
          <HeroLeftContainer id="Left">
            <ImgContainer>
              <Img src={HeroImg} alt="hero-image" />
            </ImgContainer>
          </HeroLeftContainer>
          <HeroRightContainer id="Right">
            <Title>Olá, me chamo <br /> {Bio.name}</Title>
            <TextLoop>
              Eu sou
              <Span>
                <Typewriter
                  options={{
                    strings: Bio.roles,
                    autoStart: true,
                    loop: true,
                  }}
                  components={[<Span key={Math.random()} />]} // Ensure unique key for components
                />
              </Span>
            </TextLoop>
            <SubTitle>{Bio.description}</SubTitle>
          </HeroRightContainer>
        </HeroInnerContainer>
      </HeroContainer>
    </div>
  );
};

export default HeroSection;
