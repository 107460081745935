import { useState } from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import styled, { ThemeProvider } from "styled-components";
import './App.css';
import Footer from "./components/footer/index.jsx";
import ProjectDetails from "./components/modals/ProjectDetails.jsx";
import Navbar from "./components/navbar/index.jsx";
import Experience from "./components/sections/Experience/index.jsx";
import HeroSection from "./components/sections/HeroSection/index.jsx";
import Projects from "./components/sections/Projects/index.jsx";
import Skills from "./components/sections/Skills/index.jsx";
import { darkTheme, lightTheme } from './utils/Themes.js';

const Body = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
  overflow-x: hidden;
  top: 0;
  z-index: 1;
`

const Wrapper = styled.div`
  background: linear-gradient(calc(34deg), rgba(0, 137, 255, 0.09) 19.96%, rgba(179, 19, 199, 0.06) 41.27%, rgba(0, 0, 0, 0.23) 55.83%, rgb(1 1 1) 71.75%) rgb(0, 0, 0);
  width: 100%;
`

function App() {
  const [darkMode] = useState(true);
  const [openModal, setOpenModal] = useState({ state: false, project: null });
  
  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Router >
        <Navbar />
        <Body>
          <HeroSection />
          <Wrapper>
            <Skills />
            <Experience />
            <Projects openModal={openModal} setOpenModal={setOpenModal} />
            <Footer />
          </Wrapper>
          {openModal.state &&
            <ProjectDetails openModal={openModal} setOpenModal={setOpenModal} />
          }
        </Body>
      </Router>
    </ThemeProvider>
  );
}

export default App;
