import React, { useState } from 'react';
import { projects } from '../../../data/constants';
import ProjectCard from '../../cards/ProjectCard'

import styled from 'styled-components';

export const Container = styled.div`
  background: linear-gradient(343.07deg, rgba(132, 59, 206, 0.06) 5.71%, rgba(132, 59, 206, 0) 64.83%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  align-items: center;
  padding: 40px 0 20px;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1350px;
  padding-top: 80px;
  gap: 12px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Title = styled.div`
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`;

export const Desc = styled.div`
  font-size: 18px;
  text-align: center;
  max-width: 600px;
  color: ${({ theme }) => theme.text_secondary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 16px;
  }
`;

export const ToggleButtonGroup = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.secondary + 75};
  color: ${({ theme }) => theme.secondary};
  font-size: 16px;
  border-radius: 12px;
  font-weight: 500;
  margin: 22px 0px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`

export const ToggleButton = styled.div`
  padding: 8px 18px;
  cursor: pointer;

  ${({ active, theme }) => active && `
    background: ${theme.secondary + 20};
  `
  }

  &:hover {
    background: ${({ theme }) => theme.secondary + 8};
  }

  @media (max-width: 768px) {
    padding: 6px 8px;
    border-radius: 4px;
  }
`

export const Divider = styled.div`
  width: 1px;
  background: ${({ theme }) => theme.secondary + 75};
`

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 28px;
  flex-wrap: wrap;
`;

const Projects = ({ openModal, setOpenModal }) => {
  const [toggle, setToggle] = useState('todos');

  const handleToggleChange = (value) => {
    setToggle(value);
  };

  const categories = ['todos', 'web', 'design', 'outros'];

  return (
    <Container id="projects">
      <Wrapper>
        <Title>Projetos</Title>
        <Desc>Alguns projetos nos quais participei</Desc>
        <ToggleButtonGroup>
          {categories.map((value, index) => (
            <React.Fragment key={value}>
              {toggle === value ? (
                <ToggleButton active onClick={() => handleToggleChange(value)}>
                  {value === 'todos' ? 'Todos' : value.toUpperCase()}
                </ToggleButton>
              ) : (
                <ToggleButton onClick={() => handleToggleChange(value)}>
                  {value === 'todos' ? 'Todos' : value.toUpperCase()}
                </ToggleButton>
              )}
              {index !== categories.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </ToggleButtonGroup>
        <CardContainer>
          {toggle === 'todos'
            ? projects.map((project) => (
                <ProjectCard key={project.id} project={project} openModal={openModal} setOpenModal={setOpenModal} />
              ))
            : projects
                .filter((item) => item.category === toggle)
                .map((project) => (
                  <ProjectCard key={project.id} project={project} openModal={openModal} setOpenModal={setOpenModal} />
                ))}
        </CardContainer>
      </Wrapper>
    </Container>
  );
};

export default Projects;
